HEADER,
FOOTER,
PICTURE,
IMG,
.image {
	display: none;
}

DIV {
	//page-break-inside: avoid;
	break-inside: avoid;
}


BODY {
	H4 {
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}
}

.content-container {
	padding-top: 0;
}

.layout--recipe {
	.section {
		display: none;
	}

	.section--printable {
		display: block;
	}
}